import { useEffect } from "react"

const SuperSecretRsvp = () => {
  useEffect(() => {
    window.location = "/update"
  })

  return null
}

export default SuperSecretRsvp
